import React, {useContext, useState} from 'react';
import {Context} from '../../../src/AppContext';
import {Link} from 'gatsby';
import {Button, Input, message} from 'antd';
import styled from 'styled-components';
import StaticImage from '../../../src/Components/StaticImage';
const appConfig = require('../../../src/data.json');

const MAX_WIDTH = appConfig.maxContentWidth;
const Metadata = {
  title: 'HiPrint 印刷電子商務新零售!!',
  detail:
    '致力於解決印刷業與客戶端的矛盾與阻力，一線化設計的系統。\n輕鬆解決繁雜的作業程序，系統化操作、節省成本、提高營收',
  button: {
    label: '全部商品',
    link: '/products',
  },

  feature_section: {
    title: 'WHAT WE DO?',
    items: [
      {
        title: '便利的線上諮詢',
        detail:
          '我們所承諾的條件下產生的印製問題及\r\n瑕疵，我們將為您評估狀況後，或提供\r\n重新印製或退款方式，給予消費者保障',
        image: 'about-feature-icon-1.png',
      },
      {
        title: '線上傳檔服務',
        detail:
          '疲於奔走一台電腦一個鼠標，\r\n指尖輕點，貨品送達\r\n不懂設計交給我們質優服務好。\r\n客製你的理想！一件也能做！',
        image: 'about-feature-icon-2.png',
      },
      {
        title: '專人審核稿件通知',
        detail:
          '印製過程及出貨前會確認所有產品的質量。\r\n若您發現商品任何問題時，請與我們聯絡，專人幫您確認及評估狀況。\r\n客製你的理想！一件也能做！',
        image: 'about-feature-icon-3.png',
      },
    ],
  },

  device_section: {
    title: '印刷設備介紹',
    special: {
      banner_img: 'about-device-banner.png',
    },
    items: [
      {
        title: 'Fuji Xerox 富士全錄 數位印刷機',
        detail:
          '驚艷的Ultra HD超高影像解析度處理，\r\n2400×2400 dpi輸出解析度展現卓越銳利度\r\n與一致性。呈現更銳利清晰的文字線條與更柔順的\r\n階調與漸層。多樣化的紙張適用性，滿足您對於\r\n千變萬化的紙張印製需求。',
        image: 'about-device-0.png',
      },
      {
        title: 'Mimaki UV-LED固化\r\n平台式噴墨印刷機',
        detail:
          '出色的耐用性和適合各種介質的色域，\r\n柔軟素材的商品亦能表現優異。\r\n高精度控制透明墨印刷呈現不同效果，\r\n成品帶有光澤，提高產品的精緻度。',
        image: 'about-device-1.jpg',
      },
      {
        title: 'SwissQprint LED\r\n平台式直噴機',
        detail:
          '高水準大幅面噴繪設備，各種不同形式\r\n及類別的材料都能輕鬆應對。\r\n不僅具備一般CMYK和LC/ LM的6色打印，\r\n滿足精美黑色噴繪的L-BK/ 白色及光油。',
        image: 'about-device-2.jpg',
      },
      {
        title: 'Epson 業界最高規\r\n十色高階大圖機',
        detail:
          '雙噴頭十色高階商用機，\r\n精點微噴技術，細膩輸出如藝術。\r\n1440 x1440 dpi印出清晰銳利影像，\r\n高濃度環保墨水顯色程度更好。',
        image: 'about-device-3.jpg',
      },
    ],
  },
};

const CustomerFormFields = [
  {
    name: 'name',
    placeholder: '您的姓名',
    required: true,
  },
  {
    name: 'phone',
    placeholder: '手機號碼',
    required: true,
  },
  {
    name: 'email',
    placeholder: '電子信箱',
    required: true,
    validator: (value) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(value.toLowerCase());
    },
  },
];

const EmailFormFields = [
  {
    name: 'subject',
    placeholder: '信件主旨',
    required: true,
  },
  {
    name: 'content',
    placeholder: '信件內容',
    required: true,
  },
];

class ErrAboutPage extends Error {}

export default function AboutPage(props) {
  const [data, setData] = useState({
    name: '',
    phone: '',
    tel: '',
    telExtension: '',
    email: '',
    title: '',
    content: '',
  });

  const app = useContext(Context);

  const onFormSubmit = async () => {
    try {
      // basic validating form data
      let _blankFields = [];
      let _invalidFields = [];
      for (let f of [...CustomerFormFields, ...EmailFormFields]) {
        if (!data[f.name] && f.required) {
          _blankFields.push(f.placeholder);
        } else if (f.validator && !f.validator(data[f.name])) {
          _invalidFields.push(f.placeholder);
        }
      }
      if (_blankFields.length !== 0) {
        throw new ErrAboutPage(`請填入欄位：${_blankFields.join('、')}`);
      } else if (_invalidFields.length !== 0) {
        throw new ErrAboutPage(
          `請確認欄位：${_invalidFields.join('、')}的格式`,
        );
      }

      let formData = {
        subject: data.subject,
        message: data.content,
        name: data.name,
        phone: data.phone,
        local_phone: `${data.tel}#${data.telExtension}`,
        email: data.email,
      };

      await app.actions.contact(formData);

      message.success('聯絡表單已送出！');
    } catch (err) {
      if (err instanceof ErrAboutPage) {
        message.warning(err.message);
      } else {
        message.error('聯絡表單送出失敗！');
      }
    }
  };

  const onInputChange = (field) => (e) => {
    let value = e.target.value;
    setData((pre) => ({...pre, [field]: value}));
  };

  return (
    <Wrapper>
      <a href="/products">
        <div className="banner">
          <StaticImage
            filename="About-banner.png"
            extraStyle={{width: '100%', height: '100%'}}
          />
        </div>
      </a>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 50,
          paddingBottom: 50,
        }}>
        <h1 style={{color: '#01916d'}}>為商業帶來革新</h1>
        <h3 style={{color: '#333333', marginTop: 10}}>
          體現我們持續創新的承諾，並開創新領域協助企業成長{' '}
        </h3>
        <div style={{color: '#333333', marginTop: 20, textAlign: 'center'}}>
          提供卓越的產品、服務與解決方案。
          <br />
          優化客戶各種工作環境下的商務流程。
          <br />
          建立有效活用資訊/知識的業務流程，強化客戶組織優勢。
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            alignItems: 'stretch',
            width: '80%',
            maxWidth: 1100,
            minWidth: 260,
            marginTop: 70,
          }}>
          {[
            {img: '/images/about-1.png', text: '辦公室多功能事務機及印表機'},
            {
              img: '/images/about-2.png',
              text: '商業解決方案整合 / 串連自動化流程',
            },
            {
              img: '/images/about-3.png',
              text: '量產及辦公室需求 / 多功能印刷設備',
            },
            {
              img: '/images/about-4.png',
              text: '全球服務事業 / 優化業務流程服務',
            },
          ].map((item, idx) => {
            return (
              <div
                style={{
                  width: 250,
                  margin: 10,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                key={idx}>
                <img src={item.img} width="250" />
                <div style={{marginTop: 5, padding: 5}}>{item.text}</div>
              </div>
            );
          })}
        </div>
      </div>

      <a href="https://www.eculture.tech/" target="_blank" rel="noreferrer">
        <div className="section">
          <img src="/images/about-hiprint.png" width="100%" />
        </div>
      </a>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: 50,
          paddingBottom: 50,
        }}>
        <h2 style={{color: '#17abb4'}}>協助您輕鬆處理印刷訂單與審稿！</h2>
        <div style={{color: '#7c7c7c', marginTop: 20, textAlign: 'center'}}>
          解決印刷業接單限制，整合零售電商購物平台，
          <br />
          一線化管理系統降低人工作業繁瑣困擾，
          <br />
          節省時間成本、提高營收，帶來全新電商體驗。
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            alignItems: 'stretch',
            width: '80%',
            maxWidth: 1100,
            minWidth: 260,
            marginTop: 70,
          }}>
          {[
            {
              img: '/images/about--4.png',
              title: '品牌官網輕鬆下單',
              text: 'RWD品牌電商網站整合印刷業的下單及計價方式，製作印刷商品也可以輕鬆下單。',
            },
            {
              img: '/images/about--5.png',
              title: '24小時雲端上傳檔案',
              text: '客戶無需配合廠商上班時間，隨時可在官網提供印製檔案，傳檔時間不再受拘束。',
            },
            {
              img: '/images/about--6.png',
              title: '直覺式審查印件檔案',
              text: '直覺的線上審檔流程，告別惱人的來回信件溝通，解決漏單及印製錯誤檔案問題！',
            },
            {
              img: '/images/about--7.png',
              title: '智慧化後台訂單管理',
              text: '整合電商訂單管理，串連金物、物流、電子發票設定，輕鬆一線化處理訂單瑣事！',
            },
          ].map((item, idx) => {
            return (
              <div
                style={{
                  width: 250,
                  margin: 10,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                key={idx}>
                <img src={item.img} width="150" />
                <div style={{marginTop: 15, padding: 5}}>{item.title}</div>
                <div style={{marginTop: 1, padding: 2, color: 'grey'}}>
                  {item.text}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="section grey">
        <div
          className="section-context constraint contact-section"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <div style={{color: '#01916d', fontSize: 28, fontWeight: 900}}>
            CONTACT US
          </div>
          <div style={{color: '#01916d', fontSize: 18, fontWeight: 'bold'}}>
            聯絡台灣富士軟片資訊
          </div>

          <div
            style={{
              height: 2,
              width: 195,
              backgroundColor: '#8b8681',
              marginTop: 23,
              marginBottom: 42,
            }}
          />

          <div style={{width: '80%', color: '#606060', maxWidth: 440}}>
            對於我們有任何問題與需求，歡迎填寫表單與我們聯絡！
            <br />
            也可直接來信至：dgi-fbtw-FXTWCSSD@fujifilm.com
            <br />
            客服電話：02-2597-8757
          </div>
        </div>
      </div>

      <div className="section" style={{backgroundColor: '#606060'}}>
        <div
          className="section-context constraint form-section"
          style={{backgroundColor: '#606060'}}>
          <div className="form-wrapper">
            <div className="form" style={{paddingBottom: 10, flex: 1}}>
              <img
                style={{width: '100%'}}
                src="/images/about-contact-image.png"
              />
            </div>
            <div className="form">
              {CustomerFormFields.map((field, idx) => {
                return (
                  <Input
                    key={idx}
                    value={data[field.name]}
                    onChange={onInputChange(field.name)}
                    placeholder={field.placeholder}
                  />
                );
              })}
              {EmailFormFields.map((field, idx) => {
                if (field.name === 'content') {
                  return (
                    <Input.TextArea
                      key={idx}
                      // style={{ flex: 1 }}
                      value={data[field.name]}
                      placeholder={field.placeholder}
                      onChange={onInputChange(field.name)}
                    />
                  );
                }
                return (
                  <Input
                    key={idx}
                    value={data[field.name]}
                    onChange={onInputChange(field.name)}
                    placeholder={field.placeholder}
                  />
                );
              })}
              <Button
                className="button hover-reverse-btn"
                type="primary"
                size="large"
                onClick={onFormSubmit}
                style={{
                  flexGrow: 0,
                  border: 0,
                }}>
                確認送出
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  flex: 1;

  & .constraint {
    max-width: ${MAX_WIDTH + 40}px;
    margin: 0 auto;
    padding: 0px 20px;
  }

  & > .banner {
    background-color: #ececec;
    height: 400px;
    overflow: hidden;

    @media screen and (max-width: 600px) {
      height: 300px;
    }
  }

  & > .section {
    padding: 40px 0px;
    & > .section-context {
      text-align: center;
      & > h2 {
        color: #c89664;
        font-size: 20px;
        border-bottom: 1px solid #c8c8c8;
        padding: 7px 32px;
        display: inline-block;
        margin-bottom: 40px;
      }

      & > h3 {
        color: #5a5a5a;
        font-size: 24px;
        margin-bottom: 14px;
      }

      & p {
        color: #717071;
        white-space: pre-wrap;
      }

      @media screen and (max-width: 600px) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    /* layout and color */
    & .intro-section {
      margin: 0px auto;
      text-align: center;
      & h3 {
      }
      & p {
        font-size: 16px;
        line-height: 30px;
        white-space: pre-wrap;
        margin-bottom: 30px;
      }

      & .hover-reverse-btn {
        background-color: ${appConfig.colors.main};
        color: white;
        &:hover {
          color: ${appConfig.colors.main};
          background-color: white;
        }
      }
      & .button {
        min-width: 200px;
        text-align: center;
        border-radius: 5px;
        display: inline-block;
      }
    }

    & .feature-section {
      & > .items-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & > .item-wrapper {
          display: flex;
          flex-direction: column;
          flex-basis: calc(33.33% - 20px);
          border: 1px solid #c8c8c8;
          background-color: white;
          align-items: center;
          padding: 33px 20px;

          & .icon {
            width: 98px;
            height: 98px;
            margin-bottom: 40px;
          }
          & > h2 {
            color: #5a5a5a;
            font-size: 18px;
            margin-bottom: 18px;
          }
          & > p {
            white-space: pre-wrap;
            text-align: center;
            line-height: 22px;
          }

          @media screen and (max-width: 600px) {
            flex-basis: 100%;
            margin-bottom: 10px;
          }
        }
      }
    }
    & .device-section {
      /* first section of device */
      & > .device-special-wrapper {
        & .device-special-banner {
          width: 100%;
        }
      }

      /* second section of device */
      & > .items-wrapper {
        display: flex;
        flex-wrap: wrap;

        & > .item-wrapper {
          display: flex;
          flex-direction: column;
          flex-basis: 33%;
          & h2 {
            color: #595757;
            font-size: 18px;
          }
          & p {
            white-space: pre-wrap;
            text-align: center;
          }
          @media screen and (max-width: 600px) {
            flex-basis: 100%;
          }
        }

        & > .item-wrapper.main {
          flex-direction: row-reverse;
          flex-basis: 100%;
          align-items: center;

          & > .image {
            min-width: 200px;
            flex-basis: 66.6%;
          }
          & h2 {
            text-align: left;
          }
          & p {
            text-align: left;
          }

          @media screen and (max-width: 600px) {
            flex-direction: column;
            align-items: stretch;
            & > .image {
              flex-basis: 100%;
            }
            & h2 {
              text-align: center;
            }
            & p {
              text-align: center;
            }
          }
        }
      }
    }

    & .form-section {
      & .form-wrapper {
        margin-top: 40px;
        margin-bottom: 35px;
        display: flex;
        & .form {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          & input,
          & textarea {
            border: 1px solid #cccccc;
            padding: 10px 20px;
            color: #5a5a5a;
            border-radius: 5px;
            margin-bottom: 20px;
            font-size: 16px;

            &:focus {
              outline: none;
            }
          }

          & textarea {
            flex: 1;
            resize: none;
          }

          &:last-child {
            margin-left: 40px;
          }
        }

        @media screen and (max-width: 600px) {
          flex-direction: column;

          & .form {
            &:last-child {
              margin-left: 0px;
            }
            & input,
            & textarea {
              margin-bottom: 10px;
            }
          }
        }
      }

      & .hover-reverse-btn {
        background-color: ${appConfig.colors.main};
        color: white;
        &:hover {
          color: ${appConfig.colors.main};
          background-color: white;
        }
      }

      & .button {
        min-width: 200px;
        border-radius: 5px;
        display: inline-block;
      }

      & .error-hint {
        color: tomato;
        font-size: 12px;
        margin-top: 10px;
      }

      & .success-hint {
        color: #28a689;
        font-size: 12px;
        margin-top: 10px;
      }
    }

    & .contact-section {
      & .info {
        display: flex;
        justify-content: center;
        & > .map {
          flex-basis: 50%;
          height: 300px;
        }

        & > .info-details {
          /* flex-basis: 40%; */
          /* margin-left: 20px; */

          & .info-wrapper {
            display: flex;
            margin-bottom: 20px;
            text-align: left;
          }

          & .info-wrapper > .icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            flex-shrink: 0;
          }
        }

        @media screen and (max-width: 600px) {
          flex-direction: column;
          & > .map {
            flex-basis: 100%;
            margin-bottom: 20px;
          }
          & > .info-details {
            flex-basis: 100%;
            margin-left: 0px;
          }
        }
      }
    }
  }

  & > .section.grey {
    background-color: #f6f6f6;
  }
`;
